.show {
  background: rgba(255, 213, 213, 0.56);
  height: 120px;
}
.show > img {
  width: 20px;
  float: right;
  margin: 5px;
}
.show > p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  width: 48%;
  margin-left: 27%;
  padding-top: 2%;
}

.hide {
  display: none;
}
.strng {
  color: #e53935;
  cursor: pointer;
}
strong {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .show > p {
    width: 76%;
    margin-left: 13%;
    padding-top: 5%;
    /* padding: 5%; */
  }
}
@media screen and (max-width: 430px) {
  .show > p {
    margin-left: 0;
    padding-top: 8%;
    width: 96%;
    font-size: 15px;
    /* line-height: 0px; */
  }
}
@media screen and (max-width: 320px) {
  .show > p {
    margin-left: 8px;
    padding-top: 13%;
    width: 96%;
    line-height: 22px;
    font-size: 13px;
  }
}
