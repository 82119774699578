.aboutbg {
  background: linear-gradient(
      90.46deg,
      rgba(0, 0, 0, 1.26) -66.64%,
      rgba(0, 0, 0, 0.26) 99.57%
    ),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504710/hero-sec3_by1j17.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.aboutbg > h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 3.8rem;
  line-height: 1.3em;
  letter-spacing: 1px;
  color: #ffffff;
  padding: 10% 0 0 10%;
  width: 52%;
}

.schedule {
  margin: 1% 0 0 10%;
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  background-color: #e53935;
}
.schedule:hover {
  /* padding: 6px 18px; */
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #ffffff;
  border-radius: 4px;
  /* border: 2px solid var(--primary); */
  transition: all 0.3s ease-out;
}

@media screen and (max-width: 1024px) {
  .aboutbg > h1 {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .aboutbg > h1 {
    font-size: 3.5rem;
    width: 95%;
  }
}

@media screen and (max-width: 430px) {
  .aboutbg {
    height: 95vh;
  }
  .aboutbg > h1 {
    font-size: 2rem;
    width: 97%;
    padding: 40% 0 0 10%;
  }
}
/* @media screen and (max-width: 360px) {
  .aboutbg > h1 {
    width: 97%;
  }
} */
