.plan-h2 {
  background-image: url("../Image/about-hero.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}
.plan-h2 > h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: 1px;
  color: #ffffff;
  padding: 15% 0 0 10%;
  width: 76%;
  text-align: initial;
}
.plan-display {
  background-color: #f4f4f4;
  height: 160px;
  margin: 5% 0;
  text-align: center;
}
.schedule2 {
  margin: 0;
}
.schedule2:hover {
  color: black;
}
.plan-cont {
  text-align: center;
  align-items: center;
  width: 100%;
}
.plan-cont > p {
  font-size: 20px;
  width: 60%;
  margin-left: 310px;
  padding-top: 20px;
  text-align: center;
}
.save-h2 {
  text-align: center;
  margin: 0 0 4% 0;
  padding-top: 2%;
}
.savemyplan {
  background: rgba(255, 213, 213, 0.19);
}
.conth1 {
  font-weight: 500;
  /* line-height: 60px; */
  text-align: center;
  font-size: 30px;
  margin-left: 7px;
}
.cont3 {
  margin-left: 31%;
  width: 53%;
  margin-left: 230px;
  text-align: inherit;
}
.cont4 {
  width: 50%;
  margin-left: 200px;
}

.icon-img2 {
  margin-left: 150px;
}

.icon-img1 {
  margin-left: 25%;
}
.brands {
  display: flex;
  flex-direction: row;
}
.bajaj-div {
  margin-left: 28%;
}
.bajaj-div > img {
  width: 60%;
}
@media screen and (max-width: 1024px) {
  .plan-cont > p {
    width: 77%;
    margin-left: 120px;
    margin-bottom: 37px;
  }
}

@media screen and (max-width: 768px) {
  .icon-img1 {
    margin-left: 2%;
  }
  .plan-h2 > h1 {
    width: 80%;
    font-size: 35px;
  }
  .plan-cont > p {
    width: 94%;
    font-size: 18px;
    margin-left: 23px;
  }
  .conth1 {
    font-weight: 500;
    /* line-height: 60px; */
    text-align: center;
    font-size: 20px;
    margin-left: 10px;
  }
  .cont3 {
    /* margin-left: 31%; */
    width: 100%;
    font-size: 15px;
    margin-left: 18%;
  }
  .icon-img2 {
    margin-left: 115px;
  }
  .cont4 {
    width: 58%;
    margin-left: 175px;
  }
  .bajaj-div {
    margin-left: 8%;
  }
}
@media screen and (max-width: 430px) {
  .plan-display {
    height: 195px;
  }
  .plan-h2 {
    text-align: center;
  }
  .save-h2 {
    margin-bottom: 20%;
  }
  .plan-h2 > h1 {
    width: 100%;
    font-size: 28px;
    text-align: center;
    padding-left: 0;
    padding-top: 152px;
    padding-right: 0;
    line-height: 45px;
  }
  .schedule1 {
    margin: 10% 0 0 0;
  }
  .plan-cont > p {
    width: 100%;
    font-size: 14px;
    margin-left: 0px;
    padding: 20px 5px 0 5px;
  }
  .cont3 {
    /* margin-left: 31%; */
    width: 80%;
    font-size: 15px;
    margin-left: 14%;
  }
  .cont4 {
    width: 76%;
    margin-left: 63px;
  }
  .icon-img2 {
    margin-left: 10px;
  }
  .brands {
    flex-direction: column;
    text-align: center;
  }
  .bajaj-div {
    margin-left: 0%;
  }
  .brandh2 {
    margin-bottom: 5%;
  }
}
@media screen and (max-width: 375px) {
  .plan-h2 > h1 {
    font-size: 22px;
    line-height: 30px;
    width: 100%;
  }
  .plan-display {
    height: 195px;
  }
  .conth1 {
    margin-left: 5px;
  }
}
