@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow: hidden; */
  /* font-family: "Open Sans", Arial, sans-serif; */
  font-family: "Poppins";
  scroll-behavior: smooth;
}
