@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.bg-img {
  background: linear-gradient(
      90.46deg,
      rgba(0, 0, 0, 1.26) 0.36%,
      rgba(0, 0, 0, 0.26) 99.57%
    ),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504048/samples/hero-sec-bg_jlbph5.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}
.bg-img h1 {
  color: #dddcdc;
  font-style: normal;
  font-weight: 600;
  /* font-size: 60px;
   */
  font-size: 3.6rem;
  width: 68%;
  padding: 10% 0 0 10%;
}
.bg-img h4 {
  font-style: normal;
  font-weight: 200;
  font-size: 1rem;
  line-height: 138%;
  color: #dddcdc;
  width: 37%;
  padding: 1% 0 0 10%;
}
.appointment {
  margin: 1% 0 0 10%;
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #dddcdc;
  cursor: pointer;
  background-color: #e53935;
}
.appointment:hover {
  /* padding: 6px 18px; */
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #dddcdc;
  border-radius: 4px;
  /* border: 2px solid var(--primary); */
  transition: all 0.3s ease-out;
}

@media screen and (max-width: 1496px) {
  .bg-img h1 {
    width: 71%;
  }
}

@media screen and (max-width: 1024px) {
  .bg-img h1 {
    font-size: 3.2rem;
    width: 83%;
    padding-top: 15%;
  }
  .bg-img h4 {
    width: 47%;
  }
}

@media screen and (max-width: 960px) {
  .bg-img h1 {
    font-size: 2.9rem;
    width: 86%;
  }
  .bg-img h4 {
    width: 48%;
  }
}

@media screen and (max-width: 768px) {
  .bg-img h1 {
    width: 86%;
    /* margin-left: 9%; */
    /* text-align: center; */
    font-size: 2.5rem;
    padding-top: 22%;
  }
  .bg-img h4 {
    width: 56%;
    font-size: 15px;
    /* text-align: center; */
    margin: 2% 0 0 0%;
  }
  .appointment {
    margin: 2% 0 0 10%;
  }
}

@media screen and (max-width: 430px) {
  .bg-img {
    text-align: initial;
    /* align-items: center; */
    height: 80vh;
  }
  .bg-img h1 {
    width: 92%;
    /* margin-left: 9%; */
    /* text-align: center; */
    font-size: 2rem;
    padding-top: 152px;
  }
  .bg-img h4 {
    width: 89%;
    margin: 5% 0 5% 0%;
  }
}

@media screen and (max-width: 375px) {
  .bg-img h1 {
    width: 97%;
    font-size: 1.8rem;
  }
  .bg-img h4 {
    width: 90%;
  }
  .bg-img {
    height: 90vh;
  }
}
