.profile-sec {
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
}
.profile-img {
  width: 100%;
  padding: 5% 0 0 5%;
}
.profile-img img {
  width: 80%;
}
.profile-cont {
  width: 100%;
  text-align: justify;
  padding: 5% 0 0 0;
  font-size: 1rem;
}
.profile-cont h1 {
  padding: 10% 0 0 10%;
  font-size: 3.75rem;
  font-weight: normal;
}
.profile-cont h2 {
  padding: 10% 0 0 10%;
  font-size: 2.5rem;
  font-weight: normal;
}
.p-cont {
  padding: 5% 0 2% 10%;
}
.p-cont a {
  color: #e53935;
}
.p1-cont {
  padding: 0% 0 2% 10%;
}
.about-btn {
  margin-top: 2%;
}
.about-btn:hover {
  color: #e53935;
}
/* p {
  margin-bottom: 0rem;
} */
@media screen and (max-width: 1024px) {
  .profile-cont h1 {
    font-size: 3.6rem;
    padding-top: 0%;
  }
  .profile-cont h2 {
    font-size: 1rem;
    padding-top: 5%;
    width: 90%;
  }
  p {
    margin-bottom: 0rem;
    font-size: 0.85rem;
  }
}
@media screen and (max-width: 768px) {
  .profile-cont h1 {
    font-size: 2.4rem;
  }
  p {
    font-size: 0.7rem;
  }
  .profile-img img {
    height: 100%;
  }
}

@media screen and (max-width: 430px) {
  .profile-sec {
    flex-direction: column;
  }
  .profile-cont {
    text-align: justify;
    padding: 0;
  }
  .profile-cont p {
    width: 92%;
  }
  .profile-img {
    width: 100%;
    padding: 5% 0 0 0;
  }
  .profile-cont h1 {
    font-size: 2rem;
    padding: 6% 0 0 20%;
  }
  .about-btn {
    margin: 2% 0 0 30%;
  }
}

@media screen and (max-width: 360px) {
  .profile-cont h1 {
    font-size: 2rem;
    padding: 0 0 0 13%;
  }
  .about-btn {
    margin: 2% 0 0 30%;
  }
}
