.profile-sec {
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
}
.profile-img {
  width: 100%;
  padding: 5% 0 0 5%;
}
.profile-img img {
  width: 80%;
}
.profile-cont {
  width: 100%;
  text-align: justify;
  padding: 5% 0 0 0;
  font-size: 1rem;
}
.profile-cont h1 {
  padding: 10% 0 0 10%;
  font-size: 3.75rem;
  font-weight: normal;
}
.p-cont {
  padding: 5% 0 2% 10%;
}
.p1-cont {
  padding: 0% 0 2% 10%;
}
.about-btn {
  margin-top: 2%;
}
.about-btn:hover {
  color: #e53935;
}
/* p {
    margin-bottom: 0rem;
  } */
@media screen and (max-width: 1024px) {
  .profile-cont h1 {
    font-size: 3.6rem;
    padding-top: 0%;
  }
  p {
    margin-bottom: 0rem;
    font-size: 0.85rem;
  }
}
@media screen and (max-width: 768px) {
  .profile-cont h1 {
    font-size: 2.4rem;
  }
  p {
    font-size: 0.7rem;
  }
  .profile-img img {
    height: 100%;
  }
}

@media screen and (max-width: 430px) {
  .profile-sec {
    flex-direction: column;
  }
  .profile-cont {
    text-align: justify;
    padding: 0;
  }
  .profile-cont p {
    width: 92%;
  }
  .profile-img {
    width: 100%;
    padding: 5% 0 0 0;
  }
  .profile-cont h1 {
    font-size: 2rem;
    padding: 6% 0 0 20%;
  }
  .about-btn {
    margin: 2% 0 0 30%;
  }
  .card-profile {
    flex-direction: column !important;
  }
  .card {
    width: 80% !important;
    margin: 2% 10% !important;
  }
}

@media screen and (max-width: 360px) {
  .profile-cont h1 {
    font-size: 2rem;
    padding: 0 0 0 13%;
  }
  .about-btn {
    margin: 2% 0 0 30%;
  }
}

.team-hadline {
  text-align: center;
  margin: 10% 0 0 0;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  width: 25%;
  margin: 2%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.img {
  width: 100%;
  height: 45vh;
}

.container {
  padding: 2px 16px;
  text-align: center;
}
.container > h2 {
  font-size: 1rem;
}
.card-profile {
  display: flex;
  flex-direction: row;
  /* margin-left: 5%;
  margin-right: 5%; */
}
