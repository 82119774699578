.navbar {
  background-color: #ffff;
  height: 70px;
  display: flex;
  width: 100%;
  /* justify-content: center; */
  align-items: center;
  font-size: 1.2rem;
  padding-top: 0;
  padding-bottom: 0;
}
.logo-img {
  vertical-align: middle;
  border-style: none;
  width: 50%;
  padding-bottom: 43px;
}

/* .navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
} */

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 59vw;
  justify-content: end;
}
/* .dropdown {
  margin-top: 20px;
} */
button#dropdown-item-button {
  background-color: transparent;
  color: black;
}
.nav-item {
  display: flex;
  align-items: center;
  /* height: 80px; */
}

.nav-links {
  color: black;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  /* background-color: #1888ff; */
  border-radius: 4px;
  transition: all 0.2s ease-out;
}
a {
  /* color: #007bff; */
  text-decoration: none;
  background-color: transparent;
  padding-top: 17px;
}

.fa-bars {
  color: black;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
a:hover {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 70px;
    padding-bottom: 200px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: white;
  }
  button#dropdown-item-button {
    color: white;
  }

  .nav-links:hover {
    /* background-color: #1888ff; */
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }
  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;

    padding-top: 2px;
  }
  .nav-item1 {
    padding-left: 37%;
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    /* background: #1888ff; */
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    /* color: #1888ff; */
    transition: 250ms;
  }
}
