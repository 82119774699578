.service-cont {
  width: 100%;
  text-align: center;
  align-items: center;
  margin: 10% 0 5% 0;
}

.service-flex {
  display: flex;
  flex-direction: row;
}
.service1 {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(0 0 0 / 30%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504173/priventiveDetnal_exdco9.png");
  background-size: cover;
  width: 38%;
  height: 250px;
  margin: 2px;
  background-position: center;
}
.service1:hover {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(250 65 65 / 67%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504173/priventiveDetnal_exdco9.png");
  background-size: cover;
  background-position: center;
}
.service2 {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(0 0 0 / 30%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504174/rootcanal_cclktk.png");
  background-size: cover;
  width: 38%;
  height: 250px;
  margin: 2px;
  background-position: center;
}
.service2:hover {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(250 65 65 / 67%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504174/rootcanal_cclktk.png");
  background-size: cover;
  background-position: center;
}
.service3 {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(0 0 0 / 30%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504419/crown-den_kvbsdu.jpg");
  background-size: cover;
  width: 38%;
  height: 250px;
  margin: 2px;
  background-position: center;
}
.service3:hover {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(250 65 65 / 67%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504419/crown-den_kvbsdu.jpg");
  background-size: cover;
  background-position: center;
}
.service4 {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(0 0 0 / 30%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504560/Fillings_ma3mdr.jpg");
  background-size: cover;
  width: 38%;
  height: 250px;
  margin: 2px;
  background-position: center;
}
.service4:hover {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(250 65 65 / 67%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504560/Fillings_ma3mdr.jpg");
  background-position: center;

  background-size: cover;
}
.service5 {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(0 0 0 / 30%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504175/smileDental_stpjwh.jpg");
  background-size: cover;
  width: 38%;
  height: 250px;
  margin: 2px;
  background-position: center;
}
.service5:hover {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(250 65 65 / 67%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504175/smileDental_stpjwh.jpg");
  background-position: center;

  background-size: cover;
}
.service6 {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(0 0 0 / 30%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504571/dentures_jklnqj.png");
  background-size: cover;
  width: 38%;
  height: 250px;
  margin: 2px;
  background-position: center;
}
.service6:hover {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(250 65 65 / 67%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504571/dentures_jklnqj.png");
  background-position: center;

  background-size: cover;
}
.service7 {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(0 0 0 / 30%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504176/teethRemove_llhbis.png");
  background-size: cover;
  text-align: center;
  width: 38%;
  height: 250px;
  margin: 2px;
  background-position: center;
}
.service7:hover {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(250 65 65 / 67%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504176/teethRemove_llhbis.png");
  background-position: center;

  background-size: cover;
}
.service8 {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(0 0 0 / 30%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504561/fullmothRehab1_zcppdo.png");
  background-size: cover;
  width: 38%;
  height: 250px;
  margin: 2px;
  background-position: center;
}
.service8:hover {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(250 65 65 / 67%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504561/fullmothRehab1_zcppdo.png");
  background-position: center;

  background-size: cover;
}
.service9 {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(0 0 0 / 30%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504560/emergency_rhfxb5.png");
  background-size: cover;
  width: 38%;
  height: 250px;
  margin: 2px;
  background-position: center;
}
.service9:hover {
  background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(250 65 65 / 67%)),
    url("https://res.cloudinary.com/dgebevcof/image/upload/v1636504560/emergency_rhfxb5.png");
  background-position: center;

  background-size: cover;
}
.service-cont h1 {
  color: #dddcdc;
  font-size: 1.8rem;
}
.service-h2 {
  margin: 10% 0 5% 0;
}
@media screen and (max-width: 768px) {
  .service-flex {
    flex-direction: column;
    margin-right: 4px;
  }
  .service1,
  .service2,
  .service3,
  .service4,
  .service5,
  .service6,
  .service7,
  .service8,
  .service9 {
    width: 100%;
    background-position: center;
  }
}
