.process {
  text-align: center;
  align-items: center;
  margin: 5% 0 5% 0;
}
.process-btn {
  text-align: center;
}
.icon-cont {
  display: flex;
  list-style-type: none;
}
.cont {
  width: 60%;
  margin-left: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
}
.cont1 {
  width: 60%;
  margin-left: 20px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
}
.icon-img {
  margin-left: 15%;
}
.main-process {
  text-align: justify;
  display: flex;
}
.smileH1 {
  width: 54% !important;
}
.process-btn > button {
  margin: 3% 0 5% 0;
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  background-color: #e53935;
}
.process-btn :hover {
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: black;
  border-radius: 4px;
  transition: all 0.3s ease-out;
}
.accordion {
  width: 100%;
  margin-bottom: 5%;
}
.accordion .contentBx {
  position: relative;
  margin: 10px 20px;
}
.accordion .contentBx .label {
  position: relative;
  padding: 20px 10px 10px 10px;
  height: 72px;
  background: #f4f4f4;
  color: black;
  cursor: pointer;
  font-size: 20px;
}
.accordion .contentBx .label::before {
  content: "+";
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 1.5rem;
}
.accordion .contentBx.active .label::before {
  content: "-";
}

.accordion .contentBx .content {
  position: relative;
  background: #fff;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
  overflow-y: auto;
}
.accordion .contentBx.active .content {
  height: 150px;
  padding: 20px;
  background-color: #f4f4f4;
}
.content > p {
  text-align: justify;
}
.content > p > span {
  color: #e53935;
  cursor: pointer;
}
@media screen and (max-width: 430px) {
  .main-process {
    display: block;
  }
  .process > h2 {
    font-size: 1.5rem;
  }
  .process {
    margin: 15% 5% 10% 5%;
  }
  .icon {
    width: 40px;
  }
  .icon-img {
    margin-left: 5%;
  }
  .cont {
    width: 70%;
    font-size: 15px;
    line-height: normal;
  }
  .cont1 {
    width: 70%;

    font-size: 15px;
    line-height: normal;
  }
  .accordion .contentBx .label {
    font-size: 15px;
  }
}

.profile-sec {
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
}
.profile-img {
  width: 100%;
  padding: 5% 0 0 5%;
}
.profile-img img {
  width: 80%;
}
.profile-cont {
  width: 100%;
  text-align: justify;
  padding: 5% 0 0 0;
  font-size: 1rem;
}
.profile-cont h1 {
  padding: 10% 0 0 10%;
  font-size: 3.75rem;
  font-weight: normal;
}
.p-cont {
  padding: 5% 0 2% 10%;
}
.p1-cont {
  padding: 0% 0 2% 10%;
}
.about-btn {
  margin-top: 2%;
}
.about-btn:hover {
  color: #e53935;
}
/* p {
  margin-bottom: 0rem;
} */
@media screen and (max-width: 1024px) {
  .profile-cont h1 {
    font-size: 3.6rem;
    padding-top: 0%;
  }
  p {
    margin-bottom: 0rem;
    font-size: 0.85rem;
  }
}
@media screen and (max-width: 768px) {
  .profile-cont h1 {
    font-size: 2.4rem;
  }
  p {
    font-size: 0.7rem;
  }
  .profile-img img {
    height: 100%;
  }
}

@media screen and (max-width: 430px) {
  .profile-sec {
    flex-direction: column;
  }
  .profile-cont {
    text-align: justify;
    padding: 0;
  }
  .profile-cont p {
    width: 92%;
  }
  .profile-img {
    width: 100%;
    padding: 5% 0 0 0;
  }
  .profile-cont h1 {
    font-size: 2rem;
    padding: 6% 0 0 20%;
  }
  .about-btn {
    margin: 2% 0 0 30%;
  }
}

@media screen and (max-width: 360px) {
  .profile-cont h1 {
    font-size: 2rem;
    padding: 0 0 0 13%;
  }
  .about-btn {
    margin: 2% 0 0 30%;
  }
}
.span{
  color: #e53935;
  cursor: pointer;
}
