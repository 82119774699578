.insta-heading {
  text-align: center;
  margin: 0 0 5% 0;
}
.main-foo {
  background-color: #00102e;
}
.footer-cont2 > a {
  text-decoration: none;
}
/* .snapwidget-widget {
  border: none;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
} */
.insta-post {
  display: flex;
  flex-direction: row;
  margin: 2%;
}
.btn-follow {
  width: 100%;
  text-align: center;
  align-items: center;
  margin: 2% 0 5% 0;
}
.follow {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #dddcdc;
  cursor: pointer;
  background-color: #e53935;
}

.follow:hover {
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #e53935;
  transition: all 0.3s ease-out;
}
.insta-feed {
  /* background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(0 0 0 / 30%)), */
  /* url("https://www.instagram.com/p/CTXHNaGJSIU/?utm_source=ig_web_copy_link"); */
  background-image: url("./../../Image/instafeed1.jpeg");
  background-size: cover;
  width: 38%;
  height: 250px;
  margin: 2px;
  background-position: center;
}
.insta-feed1 {
  /* background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(0 0 0 / 30%)), */
  /* url("https://www.instagram.com/p/CTXHNaGJSIU/?utm_source=ig_web_copy_link"); */
  background-image: url("./../../Image/instafeed2.jpeg");
  background-size: cover;
  width: 38%;
  height: 250px;
  margin: 2px;
  background-position: center;
}
.insta-feed2 {
  /* background: linear-gradient(0deg, rgb(0 0 0 / 68%), rgb(0 0 0 / 30%)), */
  /* url("https://www.instagram.com/p/CTXHNaGJSIU/?utm_source=ig_web_copy_link"); */
  background-image: url("./../../Image/instafeed3.jpeg");
  background-size: cover;
  width: 38%;
  height: 250px;
  margin: 2px;
  background-position: center;
}
.footer-container {
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-around;
}
.footer-cont1,
.footer-cont2,
.footer-cont3 {
  color: #dddddd;
  text-align: justify;
  font-size: 18px;
}
.footer-cont1 > h2,
.footer-cont2 > h2,
.footer-cont3 > h2 {
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
}
.footer-cont2 > div,
.footer-cont3 > p {
  cursor: pointer;
}
.footer-cont1 > p {
  margin-bottom: 22px;
  width: 321px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
}
.footer-cont2 > div {
  padding-bottom: 10px;
}
.footer-cont3 > p {
  font-style: normal;
  font-weight: 300;

  line-height: 21px;
}
.footer-cont1 > p > span,
.footer-cont3 > p > span {
  margin: 0 0 0 5px;
}
.footer-cont3 > table > tr {
  margin-top: 10px;
}
td {
  padding-bottom: 10px;
}
.td {
  padding-left: 10px;
}
/* .footer-cont2 > ul > li {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
} */
.main-foo > p {
  margin-bottom: 0;
  padding-bottom: 20px;
  text-align: center;
  color: #ffffff;
}
.main-foo > p > a {
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

@media screen and (max-width: 820px) {
  .insta-heading {
    margin: 10% 0 5% 0;
  }
  .footer-cont1 > h2,
  .footer-cont2 > h2,
  .footer-cont3 > h2 {
    font-size: 20px;
  }
  .footer-cont1 > p {
    width: 248px;
  }
  .footer-cont1 > p,
  .footer-cont3 > p {
    font-size: 14px;
  }
  .footer-cont2 > div > a {
    font-size: 14px;
  }
  td {
    font-size: 14px;
  }
  .main-foo > p {
    font-size: 14px;
  }
}

@media screen and (max-width: 540px) {
  .insta-heading {
    margin: 10% 0 5% 0;
  }
  .btn-follow {
    margin: 5% 0 10% 0;
  }
  .footer-container {
    flex-direction: column;
    margin-left: 20px;
  }
  .footer-cont3 > p {
    margin-bottom: 7px;
  }
  .footer-cont1,
  .footer-cont2,
  .footer-cont3 {
    margin-bottom: 30px;
  }
  .footer-cont1 > h2,
  .footer-cont2 > h2,
  .footer-cont3 > h2 {
    font-size: 24px;
    margin-bottom: auto;
  }
  .footer-cont2 > div {
    padding-bottom: 5px;
  }
  .insta-post {
    flex-direction: column;
  }
  .insta-feed,
  .insta-feed1,
  .insta-feed2 {
    width: 100%;
  }
}

@media screen and (max-width: 420px) {
  .copyright {
    margin: 0 5%;
  }
}
